.link{
    color: $dark-grey;
    text-decoration: none;
    
    

   &:hover{
       text-decoration: underline;
       color: darkgray;
   } 
}