.projects {
  text-align: center;
  background: rgb(241, 241, 241);
}

.subtitle {
  text-align: center;
  padding-top: 12rem;
  font-family: sans-serif;
  font-weight: 900;
  font-size: 2.78vw;
  color: rgb(165, 1, 1);
}

.subtitle--modifier {
  text-align: center;
  margin-bottom: 16px;
}

.copy__section {
  color: $black2;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 60px;
  font-weight: bold;
}

.copy__section--modifier {
  text-align: center;
  margin-bottom: 32px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  padding: 5rem;

  .card {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    padding: 1rem;

    .card-title {
      font-weight: bold;
    }

    .card-description {
      text-align: justify;
      font-size: 1em;
    }

    .container-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .product {
      height: 3.5vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(196, 0, 0);
      font-weight: bold;
      color: #ffff;
      border-radius: 3px;
      overflow: hidden;
    }
  }
}

// RESPONSIVE

@media only screen and (max-width: 600px) {
  .projects {
    text-align: center;
    background: rgb(241, 241, 241);
    margin-bottom: 1.5rem;
  }

  .subtitle {
    text-align: center;
    padding-top: 5rem;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 1.8em;
    color: rgb(165, 1, 1);
  }

  .subtitle--modifier {
    text-align: center;
    margin-bottom: 16px;
  }

  .copy__section {
    color: $black2;
    font-size: 0.8em;
    line-height: 32px;
    margin-bottom: 60px;
    font-weight: bold;
  }

  .copy__section--modifier {
    text-align: center;
    margin-bottom: 32px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin: 0;
    padding: 10px;

    .card {
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
      border-radius: 10px;
      background: rgb(255, 255, 255);

      .card-title {
        font-weight: bold;
      }

      .card-description {
        text-align: justify;
        font-size: 0.8em;
      }

      .container-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product {
        height: 2.5vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(196, 0, 0);
        font-weight: bold;
        color: #ffff;
        border-radius: 3px;
        padding: 20px;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .projects {
    text-align: center;
    background: rgb(241, 241, 241);
    margin-bottom: 1.5rem;
  }

  .subtitle {
    text-align: center;
    padding-top: 5rem;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 1.8em;
    color: rgb(165, 1, 1);
  }

  .subtitle--modifier {
    text-align: center;
    margin-bottom: 16px;
  }

  .copy__section {
    color: $black2;
    font-size: 0.8em;
    line-height: 32px;
    margin-bottom: 60px;
    font-weight: bold;
    padding-top: -1rem;
  }

  .copy__section--modifier {
    text-align: center;
    margin-bottom: 32px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin: 0;
    padding: 10px;

    .card {
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
      border-radius: 10px;
      background: rgb(255, 255, 255);

      .card-title {
        font-weight: bold;
      }

      .card-description {
        text-align: justify;
        font-size: 0.8em;
      }

      .container-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product {
        height: 2.5vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(196, 0, 0);
        font-weight: bold;
        color: #ffff;
        border-radius: 3px;
        padding: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .projects {
    text-align: center;
    background: rgb(241, 241, 241);
    margin-bottom: 1.5rem;
  }

  .subtitle {
    text-align: center;
    padding-top: 8rem;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 2.8em;
    color: rgb(165, 1, 1);
  }

  .subtitle--modifier {
    text-align: center;
    margin-bottom: 16px;
  }

  .copy__section {
    color: $black2;
    font-size: 1.5em;
    line-height: 32px;
    margin-bottom: 60px;
    font-weight: bold;
    padding-top: -1rem;
  }

  .copy__section--modifier {
    text-align: center;
    margin-bottom: 32px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin: 0;
    padding: 10px;

    .card {
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
      border-radius: 10px;
      background: rgb(255, 255, 255);

      .card-title {
        font-weight: bold;
      }

      .card-description {
        text-align: justify;
        font-size: 0.9em;
      }

      .container-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product {
        height: 2.5vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(196, 0, 0);
        font-weight: bold;
        color: #ffff;
        border-radius: 3px;
        padding: 20px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .projects {
    text-align: center;
    background: rgb(241, 241, 241);
    margin-bottom: 1.5rem;
  }

  .subtitle {
    text-align: center;
    padding-top: 8rem;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 2.8em;
    color: rgb(165, 1, 1);
  }

  .subtitle--modifier {
    text-align: center;
    margin-bottom: 16px;
  }

  .copy__section {
    color: $black2;
    font-size: 1.5em;
    line-height: 32px;
    margin-bottom: 60px;
    font-weight: bold;
    padding-top: -1rem;
  }

  .copy__section--modifier {
    text-align: center;
    margin-bottom: 32px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin: 0;
    padding: 10px;

    .card {
      box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
        rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
        rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
      border-radius: 10px;
      background: rgb(255, 255, 255);

      .card-title {
        font-weight: bold;
      }

      .card-description {
        text-align: justify;
        font-size: 0.9em;
      }

      .container-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product {
        height: 2.5vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(196, 0, 0);
        font-weight: bold;
        color: #ffff;
        border-radius: 3px;
        padding: 20px;
      }
    }
  }
}
