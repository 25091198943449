@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.rowCatalogo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #40e0d0;
  background: -webkit-linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
  background: linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
  width: 100vw;
  max-height: 100vh;
  height: 100%;
  overflow-x: hidden;
}

.columna1 {
  height: 100vh;
  max-width: 100vw;
  width: 100%;
  background: #ffffff;
  overflow-y: hidden;
}

.containerClass {
  max-width: 100vw;
  width: 100%;
  height: auto;
  text-align: justify;
}

.container-text {
  height: auto;
  padding: 1rem;
  color: rgb(5, 5, 5);
  line-height: 1.05rem;
  width: 60vw;
}

.container-text h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: rgb(255, 255, 255);
  background: #299cb9;
  height: auto;
  padding: 1rem;
}
.container-text h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  border-bottom: 2px solid rgb(201, 180, 180);
  color: rgb(255, 255, 255);
  padding: 0.2rem;
}

.container-text p {
  font-family: "Roboto", sans-serif;
  color: rgb(255, 255, 255);
}

.container-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 40vw;
  width: 100%;
  height: 40vw;
  padding-top: 30px;

  img {
    width: 10vw;
  }

  .imgPinturascreen {
    height: auto;
    width: 30vw;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }

  .imgPinturascreenSati {
    height: auto;
    width: 65%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }

  .imgPinturascreen-vk10 {
    height: auto;
    width: 60%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }

  .imgPinturascreen-esmalte {
    height: auto;
    width: 65%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }

  .imgPinturascreen-imper {
    height: auto;
    width: 80%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }

  .imgPinturascreen-iron {
    height: auto;
    width: 80%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
    margin-top: 50px;
  }

  .imgPinturascreen-pasta {
    height: auto;
    width: 70%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
    margin-top: 40px;
  }

  .imgPinturascreenB {
    height: auto;
    width: 70%;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
  }
}

.containerInicio {
  background: #40e0d0;
  background: -webkit-linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
  background: linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);

  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  .containerTwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    h1 {
      font-size: 2.7vw;
      width: 30vw;
      margin: 50px;
      font-weight: bold;
    }

    img {
      width: 45%;
    }
  }
}

.ant-menu-submenu
  .ant-menu-submenu-inline
  .ant-menu-submenu-open
  .ant-menu-submenu-selected {
  width: 200px;
  height: 100vh !important;
}

.ant-menu-item a::before {
  color: #fff;
  font-weight: 1000;
}

.volver {
  background-color: #299cb9 !important;
  color: #fff !important;
  font-weight: 1000 !important;
}

.volver:hover {
  background-color: #4ea12857 !important;
  color: rgb(247, 245, 245) !important;
  font-weight: 1000 !important;
}

.ant-menu-submenu-title {
  background-color: #299cb9 !important;
  color: #fff;
}
.ant-menu-submenu-title:hover {
  color: rgb(248, 248, 242) !important;
  background-color: #4ea12857;
}

i.ant-menu-submenu-arrow {
  color: #ffffff;
}

i.ant-menu-submenu-arrow::before {
  color: #ffffff !important;
}

i.ant-menu-submenu-arrow::after {
  color: #ffffff !important;
}

ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  background: rgba(48, 65, 64, 0.096);
}

@media only screen and (max-width: 820px) {
  .columna1 {
    height: auto;
    width: 100vw;
    background: #ffffff;
    overflow-y: hidden;
  }

  .container-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 40vw;
    width: 100%;
    height: 40vw;
    padding-top: 30px;

    .imgPinturascreen {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 50px;
      padding: 30px;
    }

    .imgPinturascreenSati {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 50px;
      padding: 30px;
    }

    .imgPinturascreen-vk10 {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 100px;
      padding: 30px;
    }

    .imgPinturascreen-esmalte {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 80px;
      padding: 30px;
    }

    .imgPinturascreen-imper {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 80px;
      padding: 30px;
    }

    .imgPinturascreen-iron {
      height: auto;
      width: 60vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 50px;
    }

    .imgPinturascreen-pasta {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 80px;
      padding: 30px;
    }

    .imgPinturascreenB {
      height: auto;
      width: 70vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 80px;
      padding: 30px;
    }
  }

  .containerInicio {
    background: #40e0d0;
    background: -webkit-linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
    background: linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);

    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .containerTwo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
      margin: auto;
      h1 {
        font-size: 6vw;
        width: 80vw;
        margin: 50px;
        font-weight: bold;
      }

      img {
        width: 80vw;
        padding: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .columna1 {
    height: auto;
    width: 100vw;
    background: #ffffff;
    overflow-y: hidden;
  }

  .container-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 40vw;
    width: 100%;
    height: 40vw;
    padding-top: 30px;

    .imgPinturascreen {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreenSati {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreen-vk10 {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreen-esmalte {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreen-imper {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreen-iron {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreen-pasta {
      height: auto;
      width: 55vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }

    .imgPinturascreenB {
      height: auto;
      width: 60vw;
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.671));
      margin-top: 120px;
      padding: 30px;
    }
  }

  .containerInicio {
    background: #40e0d0;
    background: -webkit-linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
    background: linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);

    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
