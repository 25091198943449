.container-carousel {
  // margin-top: 3rem;
  max-width: 100vw;
  max-height: 58rem;
}

.img-carousel {
  width: 100%;
  height: 58rem;
  object-fit: cover;
}

// RESPONSIVE

@media (max-width: 400.98px) {
  .container-carousel {
    margin-top: -1.5rem;
    max-width: 100vw;
    max-height: 20rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 15rem;
  }

  .img-carousel {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 401px) and (max-width: 535.98px) {
  .container-carousel {
    margin-top: 3rem;
    max-width: 100vw;
    max-height: 20rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 15rem;
  }

  .img-carousel {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 536px) and (max-width: 577.8px) {
  .container-carousel {
    margin-top: 3rem;
    max-width: 100vw;
    max-height: 20rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 15rem;
  }

  .img-carousel {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 578px) and (max-width: 666.98px) {
}

@media screen and (min-width: 667px) and (max-width: 735px) {
}

@media screen and (min-width: 736px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 833.99px) {
  .container-carousel {
    max-width: 100vw;
    max-height: 30rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 30rem;
  }

  .img-carousel {
    width: 100%;
    height: 30rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 834px) and (max-width: 895.98px) {
  .container-carousel {
    max-width: 100vw;
    max-height: 30rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 30rem;
  }

  .img-carousel {
    width: 100%;
    height: 30rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 896px) and (max-width: 1023.98px) {
}

@media screen and (min-width: 1024px) and (max-width: 1239.98px) {
  .container-carousel {
    margin-top: 3rem;
    max-width: 100vw;
    max-height: 40rem;
  }

  .container-carousel1 {
    object-fit: contain;
    max-width: 100vw;
    max-height: 40rem;
  }

  .img-carousel {
    width: 100%;
    height: 40rem;
    object-fit: cover;
  }
}

@media screen and (min-width: 1240px) and (max-width: 1379.98px) {
}

@media screen and (min-width: 1380px) and (max-width: 1450px) {
}
