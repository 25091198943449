.containerNosotros {
  height: 100vh;
  width: 100vw;
  margin: auto;
  padding: 0;

  img {
    width: 30vw;
    display: flex;
    margin: auto;
  }

  .containerText {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 60vw;

    h1 {
      margin-top: 15px;
      text-align: justify;
      font-size: 1.5vw;
    }
  }
  .linkNosotros {
    height: 3.5rem;
    width: 8rem;
    margin: 2rem;
    background: rgba(23, 200, 224, 0.986);
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
  }
}

// RESPONSIVE

@media (max-width: 400.98px) {
  .containerNosotros {
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 0;
    img {
      width: 40vw;
      display: flex;
      margin: auto;
    }

    .containerText {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 60vw;

      h1 {
        margin-top: 20px;
        text-align: justify;
        font-size: 2.5vw;
      }
    }
    .linkNosotros {
      height: 3.2rem;
      width: 6rem;
      margin: 2rem;
      background: rgb(255, 0, 128);
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 535.98px) {
  .containerNosotros {
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 0;
    img {
      width: 50vw;
      display: flex;
      margin: auto;
    }

    .containerText {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 60vw;

      h1 {
        margin-top: 20px;
        text-align: justify;
        font-size: 2.5vw;
      }
    }
    .linkNosotros {
      height: 3.2rem;
      width: 6rem;
      margin: 2rem;
      background: rgb(255, 0, 128);
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 536px) and (max-width: 577.8px) {
  .containerNosotros {
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 0;
    img {
      width: 50vw;
      display: flex;
      margin: auto;
    }

    .containerText {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 60vw;

      h1 {
        margin-top: 20px;
        text-align: justify;
        font-size: 2.5vw;
      }
    }
    .linkNosotros {
      height: 3.2rem;
      width: 6rem;
      margin: 2rem;
      background: rgb(255, 0, 128);
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

@media screen and (min-width: 578px) and (max-width: 666.98px) {
  .containerNosotros {
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 0;
    img {
      width: 50vw;
      display: flex;
      margin: auto;
    }

    .containerText {
      display: flex;
      justify-content: center;
      margin: auto;
      width: 60vw;

      h1 {
        margin-top: 20px;
        text-align: justify;
        font-size: 2.5vw;
      }
    }
    .linkNosotros {
      height: 3.2rem;
      width: 6rem;
      margin: 2rem;
      background: rgb(255, 0, 128);
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

// @media screen and (min-width: 667px) and (max-width: 735px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 250px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 12px;
//         width: 400px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 736px) and (max-width: 767px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 250px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 12px;
//         width: 400px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 833.99px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 834px) and (max-width: 895.98px) {
//   .containerNosotros {
//     img {
//       width: 30vw;
//       display: flex;
//       margin: auto;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         width: 50%;
//         margin: 2.6rem;
//         text-align: justify;
//       }
//     }
//     .linkNosotros {
//       height: 3.5rem;
//       width: 8rem;
//       margin: 2rem;
//       background: rgb(255, 0, 128);
//       color: white;
//       font-weight: 600;
//       font-size: 1.5rem;
//     }
//   }
// }

// @media screen and (min-width: 896px) and (max-width: 1023.98px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 1024px) and (max-width: 1239.98px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 1240px) and (max-width: 1379.98px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }

// @media screen and (min-width: 1380px) and (max-width: 1450px) {
//   .containerNosotros {
//     img {
//       margin-top: 30px;
//       width: 350px;
//     }

//     .containerText {
//       display: flex;
//       justify-content: center;
//       h1 {
//         text-align: justify;
//         font-size: 20px;
//         width: 600px;
//       }
//     }
//     // .containerNosotros h1 {
//     //   padding: 2rem;
//     //   font-size: 1rem;
//     //   text-align: justify;
//     // }

//     .linkNosotros {
//       height: 3.5rem;
//       margin: 2rem;
//       background: red;
//       color: white;
//       font-weight: 600;
//       font-size: 1.2rem;
//     }
//   }
// }
