//color
$primary: #07c5f5;
$dark-grey: #363636;
$light-grey: #d8d8d8;


// boton Google

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;


$gold1:#F4BD76;
$gold2:#F6CD8F;
$gray:#ffffff80;
$gray2:#f5f4f4fa;
$black1: #1A1B1F;
$black2: #30353B;
$shadow: 0 8px 8px;
$color_shadow: #00000033;
$white: #ffff;
$red: red;
$blue: #0672ff;
$green: #3db632
