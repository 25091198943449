header {
  height: auto;
}

.hero {
  position: relative;
  width: 100vw;
  height: auto;
}

.container {
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
  height: auto;
}

.img__header {
  object-fit: cover;
  width: 100vw;
  height: auto;
  max-height: 1100px;
}

.hero__main {
  position: absolute;
  width: 45%;
  height: 20px;
  top: 39% !important;
  left: 6%;
  height: 100px;
  overflow: hidden;
}

.hero__main h1 {
  font-size: 2.75vw;
}

.title--active {
  color: rgb(255, 49, 49);
  font-weight: 900;
  text-shadow: $shadow $color_shadow;
  filter: drop-shadow(4px 4px 10px rgba(160, 160, 160, 0.671));
}

// RESPONSIVE
