.container-fuid-footer {
  // margin-top: 3rem;
}

.img__footer {
  background: url(../img/v7.jpg); /* Chrome 10-25, Safari 5.1-6 */
  background: url(../img/v7.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  height: auto;
  width: 100%;
  backdrop-filter: blur(5px); /*El efecto de vidrio*/
}

// .content__footer {
//   max-width: 80%;
//   margin: auto;
// }

.seccion__footer-2 {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  color: #ffff;
  margin: auto;
  text-align: center;
  backdrop-filter: blur(5px); /*El efecto de vidrio*/
  padding: 100px;
}

.map {
  min-height: 20vw;
  min-width: 200px;
  height: auto;
  width: 30vw;
  border-radius: 10px;
  box-shadow: rgb(255, 255, 255) 0px 2px 4px,
    rgb(255, 255, 255) 0px 7px 13px -3px, rgb(255, 255, 255) 0px -3px 0px inset;
}

.constainter-text {
  min-width: 200px;
  max-height: 350px;
  height: 100%;
  width: 30rem;
}

.seccion__footer-2 p {
  line-height: 2rem;
  height: auto;
  font-size: 1.5em;
  margin: 0;
  font-weight: 900;
}

.corection {
  padding-bottom: 34px;
  font-weight: 800;
  font-family: sans-serif;
}

.corection1 {
  font-weight: 800;
  font-family: sans-serif;
}

// .seccion__footer-2 IMG {
//   margin-bottom: 15px;
// }

.line {
  width: 100%;
  margin: 0 auto;
  height: 0.1rem;
  background: rgb(184, 178, 178);
}

.container__li {
  display: flex;
}

.icon-footer {
  width: 30px;
}

.section-text {
  padding: 1rem;
}

.redContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
}

.red {
  display: inline-block;
  // border: .1rem solid rgb(153, 144, 144);
  margin: 0.5rem;
  border-radius: 50%;
}

.redes {
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
}

.f {
  color: blue;
}

.ins {
  color: $red;
}

.what {
  color: $green;
}

.copy {
  color: rgb(255, 255, 255) !important;
  font-size: 1rem !important;
  margin-top: 2rem !important;
}

// RESPONSIVE

@media screen and (min-width: 200px) and (max-width: 831px) {
  .container-fuid-footer {
    // margin-top: 3rem;
  }

  .img__footer {
    background: url(../img/v7.jpg); /* Chrome 10-25, Safari 5.1-6 */
    background: url(../img/v7.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    position: relative;
    height: auto;
    width: 100%;
    backdrop-filter: blur(5px); /*El efecto de vidrio*/
  }

  // .content__footer {
  //   max-width: 80%;
  //   margin: auto;
  // }

  .seccion__footer-2 {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    color: #ffff;
    margin: auto;
    text-align: center;
    backdrop-filter: blur(5px); /*El efecto de vidrio*/
    padding: 0;
  }

  .map {
    min-height: 40vw;
    min-width: 200px;
    height: auto;
    width: 65vw;
    margin-top: -50px;
    border-radius: 10px;
    box-shadow: rgb(255, 255, 255) 0px 2px 4px,
      rgb(255, 255, 255) 0px 7px 13px -3px,
      rgb(255, 255, 255) 0px -3px 0px inset;
    margin: 2rem;
  }

  .constainter-text {
    min-width: 200px;
    max-height: 350px;
    height: 100%;
    width: 30rem;
  }

  .seccion__footer-2 p {
    line-height: 1.5rem;
    height: auto;
    font-weight: 900;
    font-size: 1em;
    margin: 0;
  }

  .corection {
    padding-bottom: 34px;
    font-weight: 800;
    font-family: sans-serif;
  }

  .corection1 {
    font-weight: 800;
    font-family: sans-serif;
  }

  // .seccion__footer-2 IMG {
  //   margin-bottom: 15px;
  // }

  .line {
    width: 100%;
    margin: 0 auto;
    height: 0.1rem;
    background: rgb(184, 178, 178);
  }

  .container__li {
    display: flex;
  }

  .icon-footer {
    width: 30px;
  }

  .redContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;
  }

  .red {
    display: inline-block;
    // border: .1rem solid rgb(153, 144, 144);
    margin: 0.5rem;
    border-radius: 50%;
  }

  .redes {
    font-size: 2rem;
    padding: 0.5rem;
    cursor: pointer;
  }

  .f {
    color: blue;
  }

  .ins {
    color: $red;
  }

  .what {
    color: $green;
  }

  .copy {
    color: rgb(255, 255, 255) !important;
    font-size: 0.8rem !important;
    margin-top: 2rem !important;
  }
}
