.container-icons {
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 500;
  background-color: rgb(25, 247, 5);
  border-radius: 100%;
  padding-left: 6px;
  padding-right: 6px;
}

.iconoWhatsapp {
  display: flex;
  font-size: 4.5vw;
  transition: all 300ms;
  color: #ffff;
  transition-duration: 0.8s;
  filter: drop-shadow(0px 00px 8px rgb(82, 255, 2));
  background: rgb(82, 255, 2);
  padding: 0.8vw;
  border-radius: 50%;
}

.container-icons .iconoWhatsapp:hover {
  transform: rotate(20deg);
  color: #ffff;
}

@media screen and (min-width: 340px) and (max-width: 821px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  .container-icons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 500;
  }

  .iconoWhatsapp {
    display: flex;
    font-size: 80px;
    transition: all 300ms;
    color: #ffff;
    transition-duration: 0.8s;
    filter: drop-shadow(0px 00px 8px rgb(82, 255, 2));
    background: rgb(82, 255, 2);
    padding: 0.8vw;
    border-radius: 50%;
  }

  .container-icons .iconoWhatsapp:hover {
    transform: rotate(20deg);
  }
}
