button{
background-color: #f8f8f8;
cursor: pointer;
}

button.primary{
    background: #f0c040;
}
    
button.block{
width: 100%;
}

button.small{
font-size: 1.2rem;
}





