@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  overflow-x: hidden;
}

img.small {
  max-width: 5rem;
  width: 100%;
}

img.medium {
  max-width: 15rem;
  width: 100%;
}

img.large {
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}

.row.top {
  align-items: flex-start;
}

.row.start {
  justify-content: flex-start;
}

.col-1 {
  flex: 1 1 25rem;
}

.col-2 {
  flex: 2 1 50rem;
  height: 100vh;
}

.col-3 {
  flex: 3 1 75rem;
}
.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 3rem;
}

.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}

.m-5 {
  margin: 5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 3rem;
}

.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}

.p-5 {
  padding: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.red {
  color: red;
}

.yellow {
  color: yellow;
}

.green {
  color: green;
}

.border {
  border: 1px solid black;
}
