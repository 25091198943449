.background-section-main2 {
  background: url(../img/background_property_month.jpg); /* Chrome 10-25, Safari 5.1-6 */
  background: url(../img/background_property_month.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  position: relative;
  width: 100%;
  min-height: 55vw;
  height: auto;
  margin: 0;
  padding: 0;
}

.container__seccion-2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  padding: 4rem;
  height: auto;
}

.container__seccion-2 p {
  max-width: 40vw;
  width: 100%;
  height: auto;
  text-align: justify;
  font-size: 1.44vw;
  margin: auto;
}

.container__seccion-2 h3 {
  font-size: 1.78vw;
}

.imagen__main2 {
  max-width: 40vw;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  margin: auto;
}

.texto__main__2 {
  margin-top: 10rem;
}

.texto__main__2 h3 {
  text-align: center;
  font-family: sans-serif;
  font-weight: 900;
  font-size: 2.78vw;
}

// RESPONSIVE

@media screen and (min-width: 200px) and (max-width: 638.98px) {
  .background-section-main2 {
    background: url(../img/background_property_month.jpg); /* Chrome 10-25, Safari 5.1-6 */
    background: url(../img/background_property_month.jpg); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    position: relative;
    width: 100%;
    min-height: 25vw;
    height: auto;
    margin-top: -100px;
  }

  .container__seccion-2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    height: auto;
  }

  .container__seccion-2 p {
    max-width: 100vw;
    width: 100%;
    height: auto;
    text-align: justify;
    font-size: 2.6vw;
    margin: auto;
    padding-bottom: 1rem;
    margin-top: -3.2rem;
  }

  .container__seccion-2 h3 {
    font-size: 5.78vw;
  }

  .imagen__main2 {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin: auto;
  }

  .texto__main__2 {
    margin-top: 10rem;
  }

  .texto__main__2 h3 {
    text-align: center;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 4.78vw;
  }
}
