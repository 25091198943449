.container {
  width: 100vw;
  overflow: hidden;
  height: auto;
}

aside {
  position: fixed;
  width: auto;
  z-index: 1000;
  transform: translateX(-37rem);
  transition: all 0.5s ease-in-out;
}

aside.open {
  transform: translateX(0);
}

.open-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 1rem;
  height: 1rem;
  font-size: 3.5rem;
  background: none;
  cursor: pointer;
  z-index: 1000;
  padding: 3rem;
  background: rgb(211, 210, 210);
  border-radius: 50%;
  color: rgb(255, 254, 254);
  margin-right: 2rem;
  box-shadow: rgba(54, 54, 54, 0.17) 0px -23px 25px 0px inset,
    rgba(116, 115, 115, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
    rgba(182, 182, 182, 0.06) 0px 2px 1px, rgba(22, 22, 22, 0.09) 0px 4px 2px,
    rgba(105, 104, 104, 0.09) 0px 8px 4px, rgba(44, 43, 43, 0.09) 0px 16px 8px,
    rgba(146, 145, 145, 0.09) 0px 32px 16px;

  border: 1px solid rgb(255, 255, 255);
}

.open-sidebar:hover {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(103, 102, 104);
}

aside ul {
  padding: 0;
  list-style: none;
}

.close-sidebar {
  font-size: 1.6rem;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
}

.nav__hero {
  color: $black1;
  height: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav__container {
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.733);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo__navBar {
  width: 45%;
  height: auto;
  padding: 0.7rem;
}

.container-links {
  position: relative;
  display: flex;
  align-items: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2.2rem;
  background-color: #f7f7f7;
  margin-top: 0.5rem;
}

.link {
  width: 10rem;
  height: 3.5rem;
  background: rgb(5, 86, 153);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
  transition-duration: 0.6s;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-radius: 5px;
  border: 2.5px solid rgb(156, 154, 154);
  margin: 0.15rem;
}

.link:hover {
  height: 3.5rem;
  background: #9ec8f7;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.2rem;
  transition-duration: 0.6s;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-radius: 5px;
  border: 2px solid rgb(211, 206, 206);
  cursor: pointer;
}

.title {
  margin-top: 40%;
  font-size: 3vw;
  font-weight: 300;
  color: $black1;
}

.copy {
  font-size: 2.78vw;
  color: $black1;
  text-shadow: $shadow $color_shadow;
  margin: 15px 0;
}

.copy__active {
  font-weight: 700;
}

.close {
  .close-sidebar {
    font-size: 1.5rem;
    color: rgb(7, 7, 7);
    position: absolute;
    top: 0;
    right: 0;
  }
}

// RESPONSIVE

@media (max-width: 290.98px) {
  .container-links {
    width: auto;
    height: 8rem;
    position: relative;
    display: flex;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 4.2rem;
    background-color: #f7f7f7;
  }

  .link {
    width: 4rem;
    height: 2.5rem;
    background: rgb(5, 86, 153);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 0.6rem;
    transition-duration: 0.6s;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border-radius: 5px;
    border: 2.5px solid rgb(156, 154, 154);
  }

  .open-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 0.1rem;
    height: 0.1rem;
    font-size: 1.6rem;
    background: none;
    cursor: pointer;
    z-index: 1000;
    padding: 1.3rem;
    background: rgb(211, 210, 210);
    border-radius: 50%;
    color: white;
    margin-right: 2rem;
    box-shadow: rgba(54, 54, 54, 0.17) 0px -23px 25px 0px inset,
      rgba(116, 115, 115, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(182, 182, 182, 0.06) 0px 2px 1px, rgba(22, 22, 22, 0.09) 0px 4px 2px,
      rgba(105, 104, 104, 0.09) 0px 8px 4px, rgba(44, 43, 43, 0.09) 0px 16px 8px,
      rgba(146, 145, 145, 0.09) 0px 32px 16px;
    border: 1px solid rgb(243, 238, 238);
  }

  .close {
    .close-sidebar {
      font-size: 1.5rem;
      color: rgb(7, 7, 7);
      position: absolute;
      top: 40px;
      right: 0;
    }
  }
}

@media screen and (min-width: 291px) and (max-width: 600.98px) {
  .container-links {
    width: auto;
    height: 8rem;
    position: relative;
    display: flex;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 4rem;
    background-color: #f7f7f7;
  }

  .link {
    width: 5.4rem;
    height: 3rem;
    background: rgb(5, 86, 153);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 0.8rem;
    transition-duration: 0.6s;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    border-radius: 5px;
    border: 2.5px solid rgb(156, 154, 154);
    margin: 0.2rem;
  }

  .open-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 0.1rem;
    height: 0.1rem;
    font-size: 1.6rem;
    background: none;
    cursor: pointer;
    z-index: 1000;
    padding: 1.6rem;
    background: rgb(211, 210, 210);
    border-radius: 50%;
    color: white;
    margin-right: 2rem;
    box-shadow: rgba(54, 54, 54, 0.17) 0px -23px 25px 0px inset,
      rgba(116, 115, 115, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(182, 182, 182, 0.06) 0px 2px 1px, rgba(22, 22, 22, 0.09) 0px 4px 2px,
      rgba(105, 104, 104, 0.09) 0px 8px 4px, rgba(44, 43, 43, 0.09) 0px 16px 8px,
      rgba(146, 145, 145, 0.09) 0px 32px 16px;
    border: 1px solid rgb(243, 238, 238);
  }

  .close {
    .close-sidebar {
      font-size: 1.5rem;
      color: rgb(7, 7, 7);
      position: absolute;
      top: 30px;
      right: 0;
    }
  }
}
