input, select, textarea, button{

    height: 3rem;
    padding: .6rem;
    border-radius: 0.5rem;
    border: 0.1rem #a4a4a4 solid;
    font-size: 1.6rem;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}


input:hover, select:hover, textarea:hover, button:hover{
    border: 0.1rem #404040 solid;
}