* {
  margin: 0;
  padding: 0;
}

.contenedor-barra {
  ul {
    margin-right: 0px;
    width: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    position: fixed;
    top: 50%;
    right: 0px;
    z-index: 1000;
  }

  li {
    position: relative;
    transform: translateX(110px);
    background: rgb(255, 255, 255);
    list-style: none;
    padding: 0, 10px;
    transition: 0.5s;
    width: 170px;
    border-radius: 0px 0px 0px 0px;
    height: 62px;
    z-index: 500;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px;
  }
  li:nth-child(1) {
    background: rgb(255, 1, 1);
    border-radius: 20px 0px 0px 0px;
  }
  li:nth-child(2) {
    background: rgb(115, 255, 0);
  }
  li:nth-child(3) {
    background: rgb(0, 4, 255);
    border-radius: 0px 0px 0px 20px;
  }

  li:hover:nth-child(1) {
    background: #ff4a40;
    border-bottom: 1px solid rgb(255, 255, 255);
  }
  li:hover:nth-child(2) {
    background: #6ad142;
    border-bottom: 1px solid rgb(255, 255, 255);
  }
  li:hover:nth-child(3) {
    background: #47b6f5;
    border-bottom: 1px solid rgb(255, 255, 255);
  }

  li:hover {
    transform: translateX(0);
  }

  a {
    color: #ffffff;
    font-family: sans-serif;
    text-decoration: none;
    padding-left: 15px;
  }

  a:hover {
    color: #ffffff;
    font-family: sans-serif;
    text-decoration: none;
  }

  i {
    margin-right: 15px;
    padding: 10px 10px;
    border-radius: 10%;
    background-color: none;
  }

  .iconBarra {
    position: relative;
    font-size: 40px;
  }

  .insta {
    position: relative;
    right: 16px;
    color: #ffffff;
  }
  .whatsa {
    position: relative;
    right: 16px;
    color: #ffffff;
  }
  .faceb {
    position: relative;
    right: 19px;
    color: #ffffff;
  }

  .iconBarra {
    position: relative;
    font-size: 40px;
  }
}

@media (max-width: 768.98px) {
  .contenedor-barra {
    ul {
      margin-right: 0px;
      width: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;
      position: fixed;
      top: 50%;
      right: 0px;
      z-index: 1000;
    }

    li {
      position: relative;
      transform: translateX(110px);
      background: rgb(255, 255, 255);
      list-style: none;
      padding: 0, 10px;
      transition: 0.5s;
      width: 160px;
      border-radius: 0px 0px 0px 0px;
      height: 62px;
      z-index: 500;
      box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
        rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
        rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
        rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px;
    }
    li:nth-child(1) {
      background: rgb(255, 1, 1);
      border-radius: 20px 0px 0px 0px;
    }
    li:nth-child(2) {
      background: rgb(115, 255, 0);
    }
    li:nth-child(3) {
      background: rgb(0, 4, 255);
      border-radius: 0px 0px 0px 20px;
    }

    li:hover:nth-child(1) {
      background: #ff4a40;
      border-bottom: 1px solid rgb(255, 255, 255);
    }
    li:hover:nth-child(2) {
      background: #6ad142;
      border-bottom: 1px solid rgb(255, 255, 255);
    }
    li:hover:nth-child(3) {
      background: #47b6f5;
      border-bottom: 1px solid rgb(255, 255, 255);
    }

    li:hover {
      transform: translateX(0);
    }

    a {
      color: #ffffff;
      font-family: sans-serif;
      text-decoration: none;
      padding-left: 15px;
    }

    a:hover {
      color: #ffffff;
      font-family: sans-serif;
      text-decoration: none;
    }

    i {
      margin-right: 15px;
      padding: 10px 10px;
      border-radius: 10%;
      background-color: none;
    }

    .iconBarra {
      position: relative;
      font-size: 40px;
    }

    .insta {
      position: relative;
      right: 16px;
      color: #ffffff;
    }
    .whatsa {
      position: relative;
      right: 16px;
      color: #ffffff;
    }
    .faceb {
      position: relative;
      right: 19px;
      color: #ffffff;
    }

    .iconBarra {
      position: relative;
      font-size: 40px;
    }
  }
}
