.containerImagen {
  max-width: 100vw;
  height: auto;
  max-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageVkolor {
  max-height: 100%;
  width: 25%;
  height: 100%;
}
